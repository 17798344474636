export const COUNTDOWN = 'countdown';
export const DORMANT = 'Dormant';

export const RaceStatus = {
  Abandoned: 'abandoned',
  AtThePost: 'at_the_post',
  Delayed: 'delayed',
  [DORMANT]: COUNTDOWN,
  FalseStart: 'false_start',
  Finished: 'finished',
  GoingBehind: 'going_behind',
  GoingInStalls: 'going_in_stalls',
  GoingDown: 'going_down',
  Inspection: 'inspection',
  Off: 'in_play',
  Parading: 'parading',
  RaceVoid: 'race_void',
  Result: 'finished',
  WeighedIn: 'finished',
  UnderOrders: 'under_orders',
};
