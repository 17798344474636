import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { makeGetRaceStatus } from './selectors';
import RaceStatusContainer from './race_status_container';

const RaceStatusRedux = ({ eventId }) => {
  const getRaceStatus = useMemo(makeGetRaceStatus, []);
  const { startTime, status } = useSelector((state) => getRaceStatus(state, { eventId }), isEqual);

  if (!startTime) return null;

  return <RaceStatusContainer startTime={startTime} status={status} />;
};

RaceStatusRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default RaceStatusRedux;
