import { createSelector } from 'reselect';
import { omit } from 'underscore';
import makeGetEventScoreboard from './make_get_event_scoreboard';

// This selector is used to not provide fields from the scoreboard that changes
// very frequently, whoever consumes this selector wont have to worry about
// being re-rendered for those fields
export default () => createSelector(
  makeGetEventScoreboard(),
  (scoreboard) => omit(scoreboard, 'clk', 'periodKey', 'dsc'),
);
