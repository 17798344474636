import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { COUNTDOWN, RaceStatus } from './constants';
import { showCountdownTimer, startsInText } from './helpers';
import RaceStatusView from './race_status_view';

const RaceStatusContainer = ({ startTime, status }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    let interval;
    if (showCountdownTimer(status)) {
      const tick = () => {
        setTime(startsInText(startTime));
      };

      interval = setInterval(tick, 1000);
      tick();
    }

    return (() => showCountdownTimer(status) && clearInterval(interval));
  }, [startTime, status]);

  const text = showCountdownTimer(status)
    ? time
    : t(`horse_racing.race_status.${RaceStatus[status]}`);

  return text && (
    <RaceStatusView
      className={status ? RaceStatus[status] : COUNTDOWN}
      text={text}
    />
  );
};

RaceStatusContainer.propTypes = {
  startTime: PropTypes.number.isRequired,
  status: PropTypes.string,
};

RaceStatusContainer.defaultProps = {
  status: null,
};

export default RaceStatusContainer;
