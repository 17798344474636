import PropTypes from 'prop-types';
import { Button, Icon } from 'bv-components';
import { preEventPath } from 'bv-helpers/routes';
import { t } from 'bv-i18n';

import RaceStatus from 'SharedComponents/race_status';
import Market from './market';
import EventTitle from './event_title';

const CashoutIcon = () => (
  <div className="upcoming-races__icons">
    <Icon id="cashout is-alternative" />
  </div>
);
const Event = ({
  sportEventPathId, meetingId, id, market, meetingDesc, info, outcomesNumber,
}) => {
  const navigateOptions = {
    sportEventPathId,
    meetingId,
    eventId: id,
  };

  if (!market) return null;

  return (
    <div className="bvs-card upcoming-races">
      { market.ca && <CashoutIcon /> }
      <EventTitle
        meetingDesc={meetingDesc}
        eachWay={market.ew}
        pltDes={market.pltDes}
        pltD={market.pltD}
        info={info}
      />

      <RaceStatus eventId={id} />

      <Market {...market} outcomesNumber={outcomesNumber} />

      <div className="upcoming-races-see-all-wrapper">
        <Button to={preEventPath(navigateOptions)} primary className="upcoming-races__see-all">
          { t('javascript.upcoming_races.see_all_runners') }
        </Button>
      </div>
    </div>
  );
};

Event.propTypes = {
  id: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
  meetingDesc: PropTypes.string.isRequired,
  market: PropTypes.instanceOf(Object),
  info: PropTypes.string,
  outcomesNumber: PropTypes.number.isRequired,
};

Event.defaultProps = {
  market: null,
  info: '',
};

export default Event;
