const dartsScores = (scoreboard) => {
  const {
    setA, legA, pointA,
    setB, legB, pointB,
  } = scoreboard;

  if (scoreboard.bos > 1) {
    return [
      [setA, legA, pointA],
      [setB, legB, pointB],
    ];
  }

  return [
    [legA, pointA],
    [legB, pointB],
  ];
};

const defaultScores = (scoreboard) => {
  const {
    scrA, ptsA,
    scrB, ptsB,
    setNum,
  } = scoreboard;

  const [currentSetA, currentSetB] = ['A', 'B'].map((team) => [
    scoreboard[`1s${team}`],
    scoreboard[`2s${team}`],
    scoreboard[`3s${team}`],
    scoreboard[`4s${team}`],
    scoreboard[`5s${team}`],
  ][setNum - 1]);

  return [
    [scrA, currentSetA, ptsA],
    [scrB, currentSetB, ptsB],
  ];
};

export default (scoreboard) => {
  switch (scoreboard.sId) {
    case 965300:
      return dartsScores(scoreboard);
    default:
      return defaultScores(scoreboard);
  }
};
