import buildTennisScoreboard from './sports/tennis';
import goesInPlay from './goes_in_play';
import started from './started';

const sportSpecificAttrs = (scoreboard) => {
  switch (scoreboard.sId) {
    case 600: // Tennis
      return buildTennisScoreboard(scoreboard);
    default:
      return null;
  }
};

const buildScoreboard = (scoreboard) => (scoreboard ? {
  goesInPlay: scoreboard.goesInPlay || goesInPlay(scoreboard) || false,
  matchStarted: scoreboard.matchStarted || started(scoreboard),
  americanFormat: scoreboard.americanFormat || false,
  addresses: scoreboard.addresses,
  inPlay: scoreboard.inPlay,
  scrA: scoreboard.scrA,
  scrB: scoreboard.scrB,
  sId: scoreboard.sId,
  dsc: scoreboard.dsc,
  gDsc: scoreboard.gDsc,
  clk: scoreboard.clk,
  code: scoreboard.code,
  ...sportSpecificAttrs(scoreboard),
} : null);

export default buildScoreboard;
