import actionCreators from '../../../redux/action_creators';

const currentTennisSetScore = (scoreboard) => ({
  ...scoreboard,
  setA: !scoreboard.setNum ? (scoreboard.setA || 0) : scoreboard[`${scoreboard.setNum}sA`],
  setB: !scoreboard.setNum ? (scoreboard.setB || 0) : scoreboard[`${scoreboard.setNum}sB`],
});

const processors = [
  currentTennisSetScore,
];

export default (err, { body: scoreboard }) => {
  const { store } = window.reduxState;

  store.dispatch(actionCreators.updateEssentialScore(
    processors.reduce((acc, processor) => processor(acc), scoreboard),
  ));
};
