export default [
  4000, // Badminton
  433100, // Baseball
  1250, // BeachVolleyball
  3400, // Bowls
  364800, // Boxing
  17500, // Cricket
  965300, // Darts
  967500, // NineBallPool
  105700, // OtherSports
  364700, // Snooker
  96610, // Speedway
  950, // Squash
  650, // TableTennis
  600, // Tennis
  601601, // ThreeByThreeBasketball
  1200, // Volleyball
  400, // Golf
  5000, // Aussie Rules Football
  5100, // Gaelic football
  5200, // Hurling
  31400, // Waterpolo
  12000, // Netball
  // we get no data from providers on most esports
  1041100, // Esports League of Legends
  1041200, // Esports Counter-Strike
  1041700, // Esports Overwatch
  1041300, // Esports DOTA 2
  1041600, // Esports Call of Duty
  1041800, // Esports King of Glory
  1041500, // Esports Starcraft II
  1041400, // Esports Rainbow Six
];
