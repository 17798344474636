import { connect } from 'react-redux';

import Outcome from './outcome';
import { makeGetOutcome } from '../selectors';

const makeMapStateToProps = () => {
  const getOutcome = makeGetOutcome();

  return (state, props) => getOutcome(state, props.id);
};

export default connect(makeMapStateToProps)(Outcome);
