import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Form = ({ form }) => (
  form && (
  <p className="upcoming-races-coupon-form">
    {t('javascript.upcoming_races.form', { form })}
  </p>
  )
);

Form.propTypes = {
  form: PropTypes.string,
};

Form.defaultProps = {
  form: null,
};

export default Form;
