import PropTypes from 'prop-types';

const EventTitle = ({
  info, pltD, pltDes, eachWay, meetingDesc,
}) => (
  <>
    <h3 className="upcoming-races__title bvs-ellipsis">{meetingDesc}</h3>
    <p className="upcoming-races__terms">
      {`${info}${eachWay && pltD !== 1 ? ` - ${pltDes}` : ''}`}
    </p>
  </>
);

EventTitle.propTypes = {
  info: PropTypes.string.isRequired,
  pltD: PropTypes.number.isRequired,
  pltDes: PropTypes.string.isRequired,
  eachWay: PropTypes.bool.isRequired,
  meetingDesc: PropTypes.string.isRequired,
};

export default EventTitle;
