import { registerHandler } from 'event-bus';
import subscriptionsMapping from './subscriptions_mapping';

export default (scoreboard) => {
  if (scoreboard.addresses) {
    subscriptionsMapping.forEach((mapping) => {
      if (!mapping.sports || mapping.sports.includes(scoreboard.sId)) {
        registerHandler(
          scoreboard.addresses[mapping.address],
          mapping.handler,
        );
      }
    });
  }
};
