import PropTypes from 'prop-types';

import OutcomeContainer from './outcome_container';

const suspended = (st) => st !== 1;

const Market = ({ o, st, outcomesNumber }) => (
  <ul className="upcoming-races__outcomes">
    {o.slice(0, outcomesNumber).map((outcomeId) => (
      <OutcomeContainer id={outcomeId} marketSuspended={suspended(st)} key={outcomeId} />
    ))}
  </ul>
);

Market.propTypes = {
  o: PropTypes.instanceOf(Array).isRequired,
  st: PropTypes.number.isRequired,
  outcomesNumber: PropTypes.number.isRequired,
};

export default Market;
