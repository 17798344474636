import reducer from './reducer';
import actionCreators from './action_creators';
import selectors from './selectors';
import middleware from './middleware';

export default {
  reducer,
  actionCreators,
  selectors,
  middleware,
};
