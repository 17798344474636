import { createSelector } from 'reselect';
import getScoreboards from './get_scoreboards';

const getPropseventId = (_, props) => props.eventId;

export default () => createSelector(
  getScoreboards,
  getPropseventId,
  (scoreboards, eventId) => (scoreboards[eventId] || {}),
);
