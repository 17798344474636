import { setTickIntervalId, getTickIntervalId } from './tick_interval_id';

export default () => {
  const tickIntervalId = getTickIntervalId();

  if (tickIntervalId) {
    clearInterval(tickIntervalId);
    setTickIntervalId(undefined);
  }
};
