import { createSelector } from 'reselect';

const getValue = (state, value) => value;

const getOutcomes = (state) => state.outcomes;

export const makeGetOutcome = () => createSelector(
  [getOutcomes, getValue],
  (outcomes, outcomeId) => outcomes[outcomeId],
);

export default {
  makeGetOutcome,
};
