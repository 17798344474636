import { createSelector } from 'reselect';
import { Redux as EssentialScoresRedux } from 'Sportsbook/essential_scores';
import { isKnownRaceStatus } from './helpers';

export const makeGetRaceStatus = () => createSelector(
  EssentialScoresRedux.selectors.makeGetEventScoreboard(),
  (score) => ({
    status: (score.comments || []).findLast(isKnownRaceStatus)?.comm,
    startTime: score.sTs,
  }),
);
