import PropTypes from 'prop-types';
import { EnhancedPrice } from 'sportsbook-components';

const Price = ({ price, fractionalEnhancedOdds }) => (
  fractionalEnhancedOdds
    ? (
      <EnhancedPrice
        enhancedPrice={fractionalEnhancedOdds}
        oldPrice={price}
      />
    )
    : <div>{ price }</div>
);

Price.propTypes = {
  price: PropTypes.string,
  fractionalEnhancedOdds: PropTypes.string,
};

Price.defaultProps = {
  price: null,
  fractionalEnhancedOdds: null,
};

export default Price;
