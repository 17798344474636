import { t } from 'bv-i18n';
import scores from 'SportsbookCommon/overview/event_detail/sets/scores';

export default (scoreboard) => {
  const [scoresA, scoresB] = scores(scoreboard);

  return {
    headers: [
      t('javascript.tennis.periods.sets_abrv'),
      t('javascript.tennis.periods.games_abrv'),
      t('javascript.tennis.periods.points_abrv'),
    ],
    scoresA,
    scoresB,
  };
};
