import getScoreboards from './get_scoreboards';
import makeGetEventScoreboard from './make_get_event_scoreboard';
import makeGetEventNoClockScoreboard from './make_get_event_no_clock_scoreboard';
import makeGetEventMostRecentComment from './make_get_event_most_recent_comment';

export default {
  getScoreboards,
  makeGetEventScoreboard,
  makeGetEventNoClockScoreboard,
  makeGetEventMostRecentComment,
};
