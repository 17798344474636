import { compose } from 'underscore';
import { UPDATE_MARKET } from 'sportsbook-markets-duck';
import { isMarketSuspended } from 'sportsbook-helpers';
import { makeGetMarket } from 'sportsbook-markets-selector';
import { removeEventsData, upcomingRacesRemoveEvents } from './duck';

const getMarket = makeGetMarket();
const selectMarket = (state, marketId) => getMarket(state, { marketId });
const isSuspended = compose(isMarketSuspended, selectMarket);

const getEventsByMarketId = (state, marketId) => Object.values(state.upcomingRaces.componentsById)
  .flatMap(({ events }) => events.filter((event) => event.market_id === marketId));

const timeoutFns = {};

const handleMarketStatus = (dispatch, getState, marketId) => {
  const suspended = isSuspended(getState(), marketId);

  if (timeoutFns[marketId]) {
    if (!suspended) {
      clearTimeout(timeoutFns[marketId]);
      timeoutFns[marketId] = null;
    }
  } else if (suspended) {
    timeoutFns[marketId] = setTimeout(() => {
      const state = getState();
      if (isSuspended(state, marketId)) {
        dispatch(upcomingRacesRemoveEvents(marketId));
        removeEventsData(dispatch, state, getEventsByMarketId(state, marketId));
      }
    }, 5000);
  }
};

export default ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case UPDATE_MARKET:
      handleMarketStatus(dispatch, getState, action.marketId);

      break;

    default:
      break;
  }
};
