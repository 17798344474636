import { omit } from 'underscore';
import { commentCompare } from '../helpers';
import {
  UPSERT_ESSENTIAL_SCORES,
  UPDATE_ESSENTIAL_SCORE,
  UPDATE_ESSENTIAL_SCORES_CLOCKS,
  REMOVE_ESSENTIAL_SCORES,
  ADD_COMMENT,
  ADD_OVERVIEW_COMMENT,
  UPDATE_MOST_RECENT_COMMENT_DISPLAY,
} from './action_types';
import { tick as clockTick } from '../services/clock_tick';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPSERT_ESSENTIAL_SCORES:
      return action.essentialScores.reduce((acc, essentialScore) => {
        const currentScore = acc[essentialScore.eId];

        return {
          ...acc,
          [essentialScore.eId]: {
            comments: currentScore?.comments || [],
            refCount: currentScore ? currentScore.refCount + 1 : 1,
            ...essentialScore,
          },
        };
      }, state);

    case UPDATE_ESSENTIAL_SCORE: {
      const { essentialScore } = action;

      return {
        ...state,
        [essentialScore.eId]: { ...state[essentialScore.eId], ...essentialScore },
      };
    }

    case UPDATE_ESSENTIAL_SCORES_CLOCKS: {
      return Object.keys(state).reduce((acc, eventId) => ({
        ...acc,
        [eventId]: {
          ...state[eventId],
          clk: clockTick(state[eventId]),
        },
      }), {});
    }

    case REMOVE_ESSENTIAL_SCORES: {
      const { eventIds } = action;

      return eventIds.reduce((acc, eventId) => {
        const currentScore = acc[eventId];

        // If there's no score, return the same state for this eventId
        if (!currentScore) {
          return acc;
        }

        // If its the last usage of the score, remove it
        if (currentScore.refCount === 1) {
          return omit(acc, eventId);
        }

        // Otherwise decrease the refCount
        return {
          ...acc,
          [eventId]: { ...currentScore, refCount: currentScore.refCount - 1 },
        };
      }, state);
    }

    case ADD_COMMENT: {
      const { comment } = action;
      const currentScore = state[comment.eId];

      if (!currentScore) {
        return state;
      }

      return {
        ...state,
        [currentScore.eId]: {
          ...currentScore,
          comments: [
            ...(currentScore.overviewComments || []),
            comment,
          ],
        },
      };
    }

    case ADD_OVERVIEW_COMMENT: {
      const { comment } = action;
      const currentScore = state[comment.eId];

      if (!currentScore) {
        return state;
      }

      return {
        ...state,
        [currentScore.eId]: {
          ...currentScore,
          overviewComments: [
            comment,
            ...(currentScore.overviewComments || []),
          ].sort(commentCompare),
        },
      };
    }

    case UPDATE_MOST_RECENT_COMMENT_DISPLAY: {
      const { eventId } = action;
      const currentScore = state[eventId];

      if (!currentScore?.overviewComments?.length) {
        return state;
      }

      const mostRecentComment = currentScore.overviewComments[0];

      return {
        ...state,
        [currentScore.eId]: {
          ...currentScore,
          overviewComments: mostRecentComment.displayed ? [] : [
            // If the most recent comment was not being displayed
            // we set it as displayed and remove all other older comments
            {
              ...mostRecentComment,
              displayed: true,
            },
          ],
        },
      };
    }

    default:
      return state;
  }
};
