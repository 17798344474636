import {
  UPSERT_ESSENTIAL_SCORES,
  REMOVE_ESSENTIAL_SCORES,
  ADD_OVERVIEW_COMMENT,
} from './action_types';
import actionCreators from './action_creators';
import { registerScoreboard, unregisterScoreboard } from '../services/event_bus';
import { startTick, stopTick } from '../services/clock_tick';

export default (store) => (next) => (action) => {
  switch (action.type) {
    case UPSERT_ESSENTIAL_SCORES:
      action.essentialScores.forEach((scoreboard) => {
        // Add check to see if the scoreboard is already registered
        // with the refCount
        registerScoreboard(scoreboard);
      });

      break;

    case REMOVE_ESSENTIAL_SCORES:
      action.eventIds.forEach((eventId) => {
        const scoreboard = store.getState()['sportsbook/essentialScores'][eventId];

        // If there is no scoreboard for the event dont do anything
        if (!scoreboard) return;

        // If the refCount for the scoreboard is 1 it means we're gonna
        // completely remove the scoreboard so unregister
        if (scoreboard.refCount === 1) {
          unregisterScoreboard(scoreboard);
        }
      });

      break;

    default:
      break;
  }

  next(action);

  switch (action.type) {
    case UPSERT_ESSENTIAL_SCORES:
      startTick();

      break;

    case REMOVE_ESSENTIAL_SCORES:
      if (Object.keys(store.getState()['sportsbook/essentialScores']).length === 0) {
        stopTick();
      }

      break;

    case ADD_OVERVIEW_COMMENT: {
      const { comment } = action;
      const eventId = comment.eId;
      const scoreboard = store.getState()['sportsbook/essentialScores'][eventId];

      // If there is no scoreboard for the event dont do anything
      if (!scoreboard) return;

      // If there is a comment already being displayed dont do anything
      if (scoreboard.overviewComments.some((comm) => comm.displayed)) return;

      // If theres no comment being displayed atm we dispatch the
      // action to dispplay the most recent one and program an update on this
      // after the given timeout
      store.dispatch(actionCreators.updateMostRecentCommentDisplay(eventId));
      setTimeout(
        () => store.dispatch(actionCreators.updateMostRecentCommentDisplay(eventId)),
        3000,
      );

      break;
    }

    default:
      break;
  }
};
