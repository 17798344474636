import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import { Carousel, Skeleton, Icon } from 'bv-components';
import { ScrollableContext } from 'bv-contexts';

import { upcomingRacesFetchData, upcomingRacesReset } from '../duck';
import EventContainer from './event_container';

const UpcomingRacesApp = ({
  componentId,
  fetchUpcomingRaces,
  resetUpcomingRaces,
  loaded,
  events,
  config,
}) => {
  useEffect(() => {
    fetchUpcomingRaces();

    return resetUpcomingRaces;
  }, [componentId]);

  const scrollableRef = useRef();

  if (!loaded) return <Skeleton skeletonType="upcoming-races-wrapper" />;
  if (!events.length) return null;

  return (
    <ScrollableContext.Provider value={scrollableRef}>
      <div className="upcoming-races-wrapper">
        <h2 className="bvs-header bvs-ellipsis">
          <Icon type="feature-comp-title" id={`sport-${config.eventPathId}`} big />
          {config.title}
        </h2>
        <Carousel scrollStep={250} ref={scrollableRef} largeArrows>
          {events.map((event) => (
            <EventContainer
              {...event}
              outcomesNumber={config.outcomesNumber}
              key={event.id}
            />
          ))}
        </Carousel>
      </div>
    </ScrollableContext.Provider>
  );
};

UpcomingRacesApp.propTypes = {
  componentId: PropTypes.number.isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
  loaded: PropTypes.bool.isRequired,
  fetchUpcomingRaces: PropTypes.func.isRequired,
  resetUpcomingRaces: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { componentId }) => {
  const component = state.upcomingRaces.componentsById[componentId];

  return component && Object.keys(component).length > 0
    ? component
    : { events: [], config: {}, loaded: false };
};

const mapDispatchToProps = (dispatch, { componentId }) => ({
  fetchUpcomingRaces: compose(
    dispatch,
    upcomingRacesFetchData(componentId),
  ),
  resetUpcomingRaces: compose(dispatch, upcomingRacesReset(componentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingRacesApp);
