import goesInPlay from './goes_in_play';

const periodMappings = {
  0: 'notStarted',
  '-1': 'matchEnded',
};

const textPeriod = (period) => periodMappings[period];

const started = (scoreboard) => {
  if (goesInPlay(scoreboard)) {
    return textPeriod(scoreboard.code) !== 'notStarted';
  }
  return new Date(scoreboard?.startTimestamp) < new Date();
};

export default started;
