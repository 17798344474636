const pad = (number, width) => (`${number}`).padStart(width, '0');

const convertToHumanClock = (initialSeconds) => {
  const seconds = initialSeconds % 60;
  const minutes = (initialSeconds - seconds) / 60;

  if (initialSeconds < 0) return '00:00';

  return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
};

const changeClock = (scoreboard) => {
  const secondsElapsed = Math.floor(((new Date()).getTime() - scoreboard.validAt) / 1000);

  return convertToHumanClock(
    scoreboard.reversedClock
      ? scoreboard.clockInSeconds - secondsElapsed
      : scoreboard.clockInSeconds + secondsElapsed,
  );
};

export default (scoreboard) => {
  if (!scoreboard.validAt || !scoreboard.run || scoreboard.clk === '') {
    return scoreboard.clk;
  }

  return changeClock(scoreboard);
};
