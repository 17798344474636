import { unregisterHandler } from 'event-bus';
import subscriptionsMapping from './subscriptions_mapping';

export default (scoreboard) => {
  if (scoreboard.addresses) {
    subscriptionsMapping.forEach((mapping) => {
      unregisterHandler(
        scoreboard.addresses[mapping.address],
        mapping.handler,
      );
    });
  }
};
