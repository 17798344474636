import { addMiddleware } from 'redux-dynamic-middlewares';
import reducer from './duck';
import App from './components/app';
import UpcomingRacesMiddleware from './middleware';

const { addReducers } = window.reduxState;

addReducers({
  upcomingRaces: reducer,
});

addMiddleware(UpcomingRacesMiddleware);

export default App;
