import { humanTimeDistance, DAY_IN_MILLIS } from 'bv-helpers/datetime';
import { t } from 'bv-i18n';
import { DORMANT, RaceStatus } from './constants';

export const isKnownRaceStatus = ({ comm }) => Object.hasOwn(RaceStatus, comm);

export const startsInText = (startTime) => {
  const {
    hours, minutes, seconds, distance,
  } = humanTimeDistance(startTime);

  if (distance > DAY_IN_MILLIS) {
    return null;
  }

  const prefix = distance <= 0 ? '-' : '';
  const format = (value) => `${prefix}${value}`;

  return hours
    ? t('horse_racing.race_status.starts_in_hours', { hours: format(hours), minutes })
    : t('horse_racing.race_status.starts_in_minutes', { minutes: format(minutes), seconds });
};

export const showCountdownTimer = (status) => !status || status === DORMANT;
