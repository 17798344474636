import { property, propertyOf, omit } from 'underscore';

import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import { addOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import { addMarkets, removeMarkets } from 'sportsbook-markets-duck';
import fetchData from './api';

export const UPCOMING_RACES_FETCH_DATA_INIT = 'upcomingRaces/UPCOMING_RACES_FETCH_DATA_INIT';
export const UPCOMING_RACES_FETCH_DATA_SUCCESS = 'upcomingRaces/UPCOMING_RACES_FETCH_DATA_SUCCESS';
export const UPCOMING_RACES_RESET = 'upcomingRaces/UPCOMING_RACES_RESET';
export const UPCOMING_RACES_REMOVE_EVENTS = 'upcomingRaces/UPCOMING_RACES_REMOVE_EVENTS';

export const upcomingRacesFetchDataInit = (componentId) => ({
  type: UPCOMING_RACES_FETCH_DATA_INIT,
  componentId,
});

export const upcomingRacesFetchDataSuccess = (componentId) => ({ events, config }) => ({
  type: UPCOMING_RACES_FETCH_DATA_SUCCESS,
  componentId,
  events,
  config,
});

export const upcomingRacesResetSuccess = (componentId) => ({
  type: UPCOMING_RACES_RESET,
  componentId,
});

export const upcomingRacesRemoveEvents = (marketId) => ({
  type: UPCOMING_RACES_REMOVE_EVENTS,
  marketId,
});

export const removeEventsData = (dispatch, state, events) => {
  // Should we better keep the list of market ids and outcome ids in upcoming races state?
  const marketIds = events.map(property('market_id'));
  const outcomeIds = marketIds.map(propertyOf(state.markets)).flatMap(property('o'));
  const eventIds = events.map(property('id'));

  dispatch(removeOutcomes(outcomeIds));
  dispatch(removeMarkets(marketIds));
  dispatch(EssentialScoresRedux.actionCreators.removeEssentialScores(eventIds));
};

export const upcomingRacesReset = (componentId) => () => (dispatch, getState) => {
  const state = getState();
  const { events = [] } = state.upcomingRaces.componentsById[componentId] || {};
  removeEventsData(dispatch, state, events);
  dispatch(upcomingRacesResetSuccess(componentId));
};

export const addScoreboards = (events) => {
  const scoreboards = events.map(({ scoreboard }) => (scoreboard && {
    ...scoreboard.essential_scoreboard,
    comments: scoreboard.comments,
  })).filter(Boolean);

  return EssentialScoresRedux.actionCreators.upsertEssentialScores(scoreboards);
};

export const upcomingRacesFetchData = (componentId) => () => (dispatch) => {
  dispatch(upcomingRacesFetchDataInit(componentId));
  return fetchData(componentId)
    .then((data) => {
      dispatch(addOutcomes(data.outcomes));
      dispatch(addMarkets(data.markets));
      dispatch(addScoreboards(data.events));
      dispatch(upcomingRacesFetchDataSuccess(componentId)(data));
    });
};

const initialState = {
  componentsById: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPCOMING_RACES_FETCH_DATA_SUCCESS:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            events: action.events,
            config: action.config,
            loaded: true,
          },
        },
      };
    case UPCOMING_RACES_RESET:
      return {
        componentsById: {
          ...omit(state.componentsById, action.componentId),
        },
      };
    case UPCOMING_RACES_REMOVE_EVENTS: {
      const { marketId } = action;
      const componentIds = Object.keys(state.componentsById);

      return {
        componentsById: componentIds.reduce((acc, id) => {
          const { events } = state.componentsById[id];
          const filteredEvents = events.filter((event) => event.market_id !== marketId);
          return {
            ...acc,
            [id]: events.length !== filteredEvents.lenght ? {
              ...state.componentsById[id],
              events: filteredEvents,
            } : state.componentsById[id],
          };
        }, {}),
      };
    }
    default:
      return state;
  }
};
