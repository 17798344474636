import { connect } from 'react-redux';

import { makeGetMarket } from 'sportsbook-markets-selector';
import Event from './event';

const makeMapStateToProps = () => {
  const getMarket = makeGetMarket();

  return (state, props) => ({
    // TODO: Change server name for attribute for camel case ???
    market: getMarket(state, { id: props.market_id }),
  });
};

export default connect(makeMapStateToProps)(Event);
