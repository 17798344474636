import PropTypes from 'prop-types';

const RaceStatusView = ({ className, text }) => (
  <div className={`race-status ${className}`}>
    {text}
  </div>
);

RaceStatusView.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default RaceStatusView;
