import { setTickIntervalId, getTickIntervalId } from './tick_interval_id';
import actionCreators from '../../redux/action_creators';

const updateClocks = () => {
  const { store } = window.reduxState;
  store.dispatch(actionCreators.updateEssentialScoresClocks());
};

export default () => {
  const tickIntervalId = getTickIntervalId();

  // If there is not an interval already for the event scoreboard
  if (!tickIntervalId) {
    setTickIntervalId(setInterval(updateClocks, 1000));
  }
};
